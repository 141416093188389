import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getToken } from '@/auth/utils'
import axios from 'axios'

const userToken = getToken()
export default function useBranchesList() {
  const toast = useToast()
  const refBranchListTable = ref(null)
  const tableColumns = [
    { key: 'Kota', sortable: true },
    { key: 'KodeUnitKerja', sortable: true },
    { key: 'Deskripsi', sortable: true },
    { key: 'Alamat1', sortable: true },
    { key: 'Alamat2', sortable: true },
    { key: 'Telp1', sortable: true },
    { key: 'Telp2', sortable: true },
    { key: 'KodeArea', sortable: true },
    { key: 'EmailPembayaran', sortable: true },
    { key: 'EmailDeposit', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBranches = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refBranchListTable.value ? refBranchListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBranches.value,
    }
  })

  const refetchData = () => {
    refBranchListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchBranches = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
      .then(response => {
        console.log(sortBy.value)
         let sorted = null
        totalBranches.value = response.data.Payload.length
        if (sortBy.value === 'KodeUnitKerja') {
          let sortVal = 1
          if (isSortDirDesc.value === true) sortVal = -1
          else sortVal = 1
          console.log(isSortDirDesc.value)
          sorted = response.data.Payload.sort((a, b) => parseInt(a.KodeUnitKerja) > parseInt(b.KodeUnitKerja) ? sortVal : sortVal * -1)
          console.log(sorted)
        } else {
          sorted = response.data.Payload
        }
        const sliced = sorted.slice(
          (currentPage.value - 1) * perPage.value,
          (currentPage.value) * perPage.value,
        )
        console.log(sliced)
        callback(sliced)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchRoles = () => {
    console.log(userToken)
    console.log('userData')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .get(process.env.VUE_APP_API_GET_ROLES, { headers })
      .then(response => {
        console.log(response)
        const temp = [
          { label: 'Admin', value: 'admin' },
          { label: 'Author', value: 'author' },
          { label: 'Editor', value: 'editor' },
          { label: 'Maintainer', value: 'maintainer' },
          { label: 'Subscriber', value: 'subscriber' },
        ]
        return temp
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchBranches,
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    totalBranches,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBranchListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
