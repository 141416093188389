<template>
  <b-sidebar
    id="add-new-branch-sidebar"
    :visible="isAddNewBranchSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-branch-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Branch
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="addNewBranchForm"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewBranch"
          @reset.prevent="resetForm"
        >
          <!-- Kota -->
          <validation-provider
            #default="validationContext"
            name="Kota"
            rules="required"
          >
            <b-form-group
              label="Kota"
              label-for="Kota"
            >
              <b-form-input
                id="Kota"
                v-model="Kota"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Jakarta"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Kode Unit Kerja -->
          <validation-provider
            #default="validationContext"
            name="Kode Unit Kerja"
            rules="required"
          >
            <b-form-group
              label="Kode Unit Kerja"
              label-for="KodeUnitKerja"
            >
              <b-form-input
                id="KodeUnitKerja"
                v-model="KodeUnitKerja"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Deskripsi -->
          <validation-provider
            #default="validationContext"
            name="Deskripsi"
            rules="required"
          >
            <b-form-group
              label="Deskripsi"
              label-for="Deskripsi"
            >
              <b-form-input
                id="Deskripsi"
                v-model="Deskripsi"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Alamat 1 -->
          <validation-provider
            #default="validationContext"
            name="Alamat1"
            rules="required"
          >
            <b-form-group
              label="Alamat1"
              label-for="Alamat1"
            >
              <b-form-input
                id="Alamat1"
                v-model="Alamat1"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Alamat 2 -->
          <validation-provider
            #default="validationContext"
            name="Alamat2"
            rules="required"
          >
            <b-form-group
              label="Alamat2"
              label-for="Alamat2"
            >
              <b-form-input
                id="Alamat2"
                v-model="Alamat2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Telp1 -->
          <validation-provider
            #default="validationContext"
            name="Telp1"
            rules="required"
          >
            <b-form-group
              label="Telp1"
              label-for="Telp1"
            >
              <b-form-input
                id="Telp1"
                v-model="Telp1"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Telp2 -->
          <validation-provider
            #default="validationContext"
            name="Telp2"
            rules="required"
          >
            <b-form-group
              label="Telp2"
              label-for="Telp2"
            >
              <b-form-input
                id="Telp2"
                v-model="Telp2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
                    <!-- KodeArea -->
          <validation-provider
            #default="validationContext"
            name="KodeArea"
            rules="required"
          >
            <b-form-group
              label="Kode Area"
              label-for="KodeArea"
            >
              <b-form-input
                id="KodeArea"
                v-model="KodeArea"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- EmailPembayaran -->
          <validation-provider
            #default="validationContext"
            name="EmailPembayaran"
            rules="required"
          >
            <b-form-group
              label="Email Pembayaran"
              label-for="EmailPembayaran"
            >
              <b-form-input
                id="EmailPembayaran"
                v-model="EmailPembayaran"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email Deposit -->
          <validation-provider
            #default="validationContext"
            name="EmailDeposit"
            rules="required"
          >
            <b-form-group
              label="Email Deposit"
              label-for="EmailDeposit"
            >
              <b-form-input
                id="EmailDeposit"
                v-model="EmailDeposit"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { getToken } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useBranchsList from './useBranchsList'

const branchToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${branchToken}`,
}
const KodeUnitKerja = ''
const Password = ''
const ConfirmPassword = ''
const Kota = ''
const PhoneNumber = ''
const Gender = ''
const RoleID = ''
const BranchID = ''
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBranchSidebarActive',
    event: 'update:is-add-new-branch-sidebar-active',
  },
  props: {
    isAddNewBranchSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      roles: [],
      branches: [],
      KodeUnitKerja,
      Password,
      ConfirmPassword,
      Kota,
      PhoneNumber,
      Gender,
      RoleID,
      BranchID,
    }
  },
  setup() {
    const blankBranchData = {
      Kota: '',
      branchname: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const genders = [
          { label: 'Male', value: 'L' },
          { label: 'Female', value: 'P' },
        ]
    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
    const resetbranchData = () => {
      branchData.value = JSON.parse(JSON.stringify(blankBranchData))
    }

    const onSubmit = () => {
      axios
          .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
          .then(response => {
            const branchesJoin = response.data.Payload.map(elem => (
              { label: elem.Kota, value: elem.BranchID }
            ))
            this.branches = branchesJoin
          })
          .catch(err => {
              console.log(err)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetbranchData)

    return {
      branchData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      genders,
    }
  },
  mounted() {
      this.getRoles()
      this.getBranches()
  },
  methods: {
      getRoles() {
          axios
              .get(process.env.VUE_APP_API_GET_ROLES, { headers })
              .then(response => {
                const rolesJoin = response.data.Payload.map(elem => (
                  { label: elem.BranchRoleName, value: elem.BranchRoleID }
                ))
                this.roles = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => (
                  { label: elem.Kota, value: elem.BranchID }
                ))
                this.branches = branchesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
    addNewBranch() {
      this.$refs.addNewBranchForm.validate().then(success => {
        const payload = {
            Kota: this.Kota,
            KodeUnitKerja: this.KodeUnitKerja,
            Deskripsi: this.Deskripsi,
            Alamat1: this.Alamat1,
            Alamat2: this.Alamat2,
            Telp1: this.Telp1,
            Telp2: this.Telp2,
            KodeArea: this.KodeArea,
            EmailPembayaran: this.EmailPembayaran,
            EmailDeposit: this.EmailDeposit,
          }
          console.log(payload)
        if (success) {
          axios
          .post('https://api.geolims.com/user_service/addbranch', payload, { headers })
          .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.Status === 1) {
                this.$root.$emit('refreshTable', 'refreshTable')
                // this.$parent.refBranchListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-branch-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
